
#headingHR{
    background-color: #E9573F;
    border: none;
    height: 4px;
}
.switch{
  font-family:'source sans pro';
  font-weight:bold;
  text-align:center;
}
#headingHR2{
    background-color: #48CFAD;
    border: none;
    height: 4px;
}
#headingHR3{
    background-color: #F6BB42;
    border: none;
    height: 4px;
}
#headingHR4{
    background-color: #8CC152;
    border: none;
    height: 4px;
}
#formFirstDiv{
  /*   background-position: center;
    background-repeat: no-repeat; 
    background-size: 400px 400px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/arogyasarthichikitsalya.appspot.com/o/Logo.svg?alt=media&token=1731ce96-3c38-409f-aa8b-b30c56cfd94c");
  */
}
::-webkit-scrollbar {
    width: 10px;
   
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d6dee1; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #656D78; 
  }