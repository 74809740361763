#main__nav{
    box-shadow: 0 4px 6px -2px rgba(0,0,0,0.5);
    position: sticky;
    top:0;
    z-index: 5;
    background-color: #fff;
    width: 100%;
    height:90px;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}
#navHeadingTitle{
    display: none;
}
#profile{
    display: none;
}
#third__Nav{
    display: none;
}
#menuButton{
    position: absolute;
    right: 0px;
}
#SMIcons{
    display: none;
}
#navImglogo{
    height: 90px;
    padding:0px;
    margin-left:-15px;
}
#hamburger{
    height:40px;
    width:40px;
}
#nav__list{
    list-style-type: none;
    font-family: 'Poppins', sans-serif;
    font-size:larger;
    letter-spacing: 0.015625em;
}
#offcanvas__Header{
    font-family: Raleway;
    font-weight: 600;
}
.off_list{
    margin: 10px 0;
}
#forPracticioners{
    border: none;
    font-family: 'Poppins', sans-serif;
    font-size:inherit;
    color: black;
    letter-spacing: 0.015625em;
    margin-left: -15px;
}
#second__Nav{
    display: none;
}
#gmap{
    display: none;
}
@media (min-width:450px) {
    #navImglogo{
        height: 200px;
        width: 100%;
        margin-left: -80px;
    }
    #navHeadingTitle{
        display: inline-block;
       
        margin-right: 150px;
        margin-top: 10px;
        height:60px;

    }
   
    #gmap{
        display: none;
    }
    #gmap.show{
        z-index: 10;
        display: inline-block;
        position: fixed;
        top:30%;
        padding-left: 40%;
        justify-content: center;
    }
    #main__nav{
        position: static;
        height: 90px;     
    }
    
    #second__Nav{
        display: block;
        position:sticky;
        top: -1px;
        z-index: 2;
        background-color: #fff;
        border-top: 1px solid black;
        box-shadow: 0 4px 6px -2px rgba(0,0,0,0.5);
        padding-top: 5px;
        padding-bottom: 5px;
    
    }
    #second__NavRow{
        font-family: 'Poppins', sans-serif;
     
        text-align: center;
        z-index: 3;
    }
    #third__Nav{
        display: block;
        position:sticky;
        padding-top: 5px;
        padding-bottom: 5px;
        top: 36px;
        z-index: 2;
        background-color: #fff;
        border-top: 1px solid black;
        box-shadow: 0 4px 6px -2px rgba(0,0,0,0.5);
    
    }
    #mainNavRowSocialMedia{
        justify-content: end;
    }
  
    #hamburger{
        display: none;
    }
    #menuButton{
        display: none;
    }
    #SMIcons{
        display: inline-block;
        height:45px;
        width:75px;
        padding-right: 15px;
        padding-left: 15px;
    }

}