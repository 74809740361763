#footer{
    
    height:440px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/sim-math.appspot.com/o/footer.jpg?alt=media&token=4a3ce795-ffe9-49eb-a0e9-0009275ece97");
}
#gmap{
    display: none;
}
#gmap.show{
    z-index: 10;
    display: inline-block;
    position: fixed;
    bottom:30%;
    padding-left: 15%;
    justify-content: center;
}
#FSMIcons{
    display: inline-block;
    height:45px;
    width:50px;
    margin-left: -10px;
    margin-top: -3px;
}

@media (min-width:450px){
    #footer{
        height:240px;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/sim-math.appspot.com/o/top-view-ferns-leaves-with-copy-space%20(1).jpg?alt=media&token=2281807c-43e8-4795-a57c-743a06024170");
    }
    #gmap{
        display: none;
    }
    #gmap.show{
        z-index: 10;
        display: inline-block;
        position: fixed;
        bottom:30%;
        padding-left: 40%;
        justify-content: center;
    }
    #FSMIcons{
        display: inline-block;
        height:45px;
        width:50px;
        margin-left: -10px;
        margin-top: -3px;
    }
  
    
}
